.projects-main{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    .card{
        //border: 2px solid white;
        background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.6),  rgba(255, 255, 255, 0.3));
        z-index: 2;
        backdrop-filter: blur(2rem);
        border-radius: 1rem;
        font-weight: 300;
        opacity: 0.7;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 17px;
        font-style: normal;
        border: 1px solid black;
        .card-head{
            border-bottom: 3px solid black;
            color: white;
            width: 100%;
            text-align: center;
        }

    }
 }