@import 'variables';
@import 'fa';
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import "~bootstrap/scss/bootstrap";

@mixin flexRow($direction) {
   display: flex;
   flex-direction: $direction;
}


@mixin pageButtons {
      margin-top: 3rem;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 15px;
      color: black;
      text-decoration: none;
      border: 1px solid black;
      padding: 1rem;
      &:hover{
         background: black;
         color: white;
     }
}

@mixin cardButtons {
   margin-top: 3rem;
   box-sizing: border-box;
   font-weight: 500;
   font-size: 15px;
   background: black;
   color: white;
   text-decoration: none;
   border: 2px solid white;
   padding: 1rem;
   &:hover{
      background: white;
      color: black;
  }
}


.container{
   @include flexRow(column);
   align-items: center;
}

.resp{
   @include flexRow(row);
   align-items: center;
}

header{
   @include flexRow(column);
   width: 100%;
   background-color: $headerColor;
   font-family: $fontFamily;
   opacity: 0.9;
}

nav {
   @include flexRow(row);
   justify-content: space-between;
   height: 115px;
   background: rgba(0, 0, 0, 0.45);
}

.showcase-grid{
   @include flexRow(column);
}

.logo{
   @include flexRow(row);
   align-items: flex-start;
   padding: 2rem 2rem;
   margin: 0rem 1rem;
}

.menu-items{
   @include flexRow(row);
   align-items: flex-end;
   justify-content: space-evenly;
   a{
      padding: 1rem 1rem;
      margin: 0rem 1rem;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      color: #FFFFFF;
      text-decoration: none;
      z-index: 5;
   }
}

h1, h2, h4, h5, h6{
   margin: 0;
   padding: 0.5em 0;
}

p{
   margin: 0;
   padding: 0;
}

#section2{
   @include flexRow(row);
   align-items: center;
   justify-content: space-evenly;
}

.card{
   @include flexRow(column);
   width: 250px;
   height: 320px;
   //border: 3px solid black;
   align-items: center;
   margin: 1rem;
   padding: 2rem;
   text-align: justify;

   a{
      @include cardButtons();
   }
}

#section3{
   @include flexRow(column);
   align-items: center;
   justify-content: space-evenly;
   h2{
      margin-top: 3rem;
   }
   .icon-container{
      @include flexRow(row);
      align-items: center;
      justify-content: space-evenly;
      i{
         margin: 4rem;
      }
   }
}

#section4{
      @include flexRow(column);
      justify-content: space-between;
      align-items: center;
      margin: 2rem 0rem;
      //width: 100%;

   .sec4-desc{
      width: 500px;
      height: 100px;
      text-align: justify;
      margin-right: 1rem;
   }

   button{
      margin-top: 1rem;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 15px;
      color: black;
      text-decoration: none;
      border: 1px solid black;
      padding: 1rem;
      border-radius: 1rem;
      &:hover{
         background: black;
         color: white;
     }
   }
}

.sec4-desc{
   float: right;
}

.sec-row img{
   float: left;
   width: 250px;
   height: 250px;
}


footer{
    @include flexRow(row);
    justify-content: space-between;
    width: 100%;
    background-color: $headerColor;
    font-family: $fontFamily;
}

.soc-med-icons{
   @include flexRow(row);
   align-items: flex-start;
   justify-content: space-evenly;
   a{
      padding: 1rem 0rem;
      margin: 0rem 1rem;
   }
}


.copyright{
   @include flexRow(row);
   align-items: flex-end;
   border: 1px solid black;
   padding: 1rem 2rem;
   font-family: $fontFamily;
   font-style: normal;
   font-weight: bold;
   font-size: 15px;
   color: #FFFFFF;
}

.btn-header{
   display: inline-block;
   background: black;
   color: white;
   text-decoration: none;
   padding: 0.5em 1em;
   border: 5px solid white;
   margin: 2em 2em;
   width: 267px;
   height: 74px;
   box-sizing: border-box;
   font-size: 25px;
   font-style: normal;
   font-weight: 700;
}
.btn-header:hover{
   background: white;
   color: black;
}

main{
   background-image: linear-gradient( 179.4deg,  rgba(12,20,69,1) -16.9%, rgba(71,30,84,1) 119.9% );
}

.section-header{
    margin-top: 30px;
    font-weight:bold;
}

.prow{
   margin-left: 0;
}

.srow{
   margin-left: 0;
}