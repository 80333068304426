.fa-instagram {
    color: transparent;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text;
    }

.fa-twitter, .fa-twitter-square {
    color: #00aced;
}

.fa-linkedin, .fa-linkedin-square {
    color: #007bb6;
}

.fa-discord, .fa-discord-square {
    color: rgb(114, 137, 218);
}

.fa-github, .fa-github-square {
    color: white;
}