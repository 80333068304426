.skills-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem;
}

.skills{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    //border: 2px solid white;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.6),  rgba(255, 255, 255, 0.3));
    z-index: 2;
    backdrop-filter: blur(2rem);
    border-radius: 1rem;
    font-weight: 300;
    opacity: 0.8;
    color: white;
    border: 1px solid black;
 }

 .ficon{
     padding-left: 4rem;
     padding-right: 1rem;
 }

 .sk-description{
    display: flex;
    flex-direction: column;
    padding-right: 4rem;
    padding-left: 1rem;
}

.stack-title{
    align-items: center;
}

