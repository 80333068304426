/* header showcase */
#showcase{
    min-height: 868px;
    color: white;
    text-align: center;
    background: rgba(0, 0, 0, 0.45);
}

.showcase-all{
    //position: absolute;
    background: #333 url('../../img/setup.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 0;
}

#showcase-parent{
    margin: 0;
    z-index: -1;
}

#top-heading{
    font-size: 80px;
    font-style: normal;
    font-weight: 900;
    line-height: 98px;
    text-align: center;
    color: white;
    background-color: black;
    opacity: 0.8;
}

#lower-heading{
    font-size: 50px;
    font-style: normal;
    font-weight: 900;
    line-height: 98px;
}

#showcase-text{
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    margin: 0 auto;
    text-align: center;
    color: white;
    background-color: black;
    opacity: 0.8;
}

#showcase-grp-btns{
    //margin: 2em 0;
    text-align: center;
    background-color: black;
    opacity: 0.8;
}

.headshot{
    text-align: center;
    background-color: black;
    opacity: 0.8;
}
