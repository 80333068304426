#portrait{
    width: 300px;
    height: 100%;
    border-radius: 1rem;
    height: auto;
    border: 1px solid black;
}
.about-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding: 1rem 1rem;
}
.about-me{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 2rem 8rem;
    //border: 2px solid white;
    //background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.6),  rgba(255, 255, 255, 0.3));
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.6),  rgba(255, 255, 255, 0.3));
    //background-color: white;
    //box-shadow: 0 15px 20px rgba(0, 0, 0, 0.356);
    z-index: 2;
    backdrop-filter: blur(2rem);
    border-radius: 1rem;
    font-weight: 500;
    opacity: 0.8;
    color: white;
    border: 1px solid black;
}

.description{
    text-align: justify;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 18px;
    font-weight: 300;
    float: left;
}

.content-title{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    //margin-top: 2rem;
    color: white;
}

